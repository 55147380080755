// import React from "react";

// export const Testimonials = (props) => {
//   return (
//     <div id="testimonials">
//       <div className="container">
//         <div className="section-title text-center">
//           <h2>What our clients say</h2>
//         </div>
//         <div className="row">
//           {props.data
//             ? props.data.map((d, i) => (
//                 <div key={`${d.name}-${i}`} className="col-md-4">
//                   <div className="testimonial">
//                     <div className="testimonial-image">
//                       {" "}
//                       <img src={d.img} alt="" />{" "}
//                     </div>
//                     <div className="testimonial-content">
//                       <p>"{d.text}"</p>
//                       <div className="testimonial-meta"> - {d.name} </div>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             : "loading"}
//         </div>
//       </div>
//     </div>
//   );
// };



import React, { useEffect, useState } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';

export const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const placeId = 'ChIJp9_HtS-jEmsR1qFcYRvoAI8'; // Your place ID
  const MAX_CHARACTERS = 200; // Set the maximum number of characters for the review text

  useEffect(() => {
    // Fetch reviews from the PHP proxy
    const fetchReviews = async () => {
      const url = `/proxy.php?place_id=${placeId}`; // Updated to call the PHP proxy

      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error(`Error: ${response.status} - ${response.statusText}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Full Response:', data); // Log the full response

        if (data.result && data.result.reviews) {
          const topReviews = data.result.reviews.slice(0, 5);
          setReviews(topReviews);
        } else {
          console.error("No reviews found for the place");
        }
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      }
    };

    fetchReviews();
  }, [placeId]);

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  // Function to render star ratings with a reduced size
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const stars = [];
    
    const starStyle = { color: '#FFD700', fontSize: '80%' }; // Reduced star size

    // Create full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<i className="fas fa-star" style={starStyle} key={`full-${i}`}></i>);
    }

    // Create half star
    if (halfStar) {
      stars.push(<i className="fas fa-star-half-alt" style={starStyle} key="half"></i>);
    }

    return stars;
  };

  return (
    <div id="reviews">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {reviews.length > 0 ? (
            reviews.map((review, i) => (
              <div key={i} className="col-md-4">
                <div className="review">
                  {review.profile_photo_url && (
                    <div className="review-image">
                      <img src={review.profile_photo_url} alt={review.author_name} />
                    </div>
                  )}
                  <div className="review-content">
                    <h4>{review.author_name}</h4>
                    <div className="rating">
                      {renderStars(review.rating)}
                    </div>
                    <p>"{truncateText(review.text, MAX_CHARACTERS)}"</p>
                    
                    <div className="review-meta"> - {review.author_name} </div>
                    <p>{review.relative_time_description}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>Loading reviews...</div>
          )}
        </div>
      </div>
    </div>
  );
};



// import React, { useEffect, useState } from "react";
// import '@fortawesome/fontawesome-free/css/all.min.css';


// export const Reviews = () => {
//   const [reviews, setReviews] = useState([]);
//   const placeId = 'ChIJp9_HtS-jEmsR1qFcYRvoAI8';
//   const apiKey = 'AIzaSyBpzt5yXMszdsT8cmArVOmGeP-pyZTHPPs'; // Replace with your actual API key
//   const MAX_CHARACTERS = 200; // Set the maximum number of characters for the review text

//   useEffect(() => {
//     // Fetch reviews from the Google Places API
//     const fetchReviews = async () => {
//       const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}&fields=${fields}&key=${key}`;
//       // const url = `http://localhost:3001/api/place/details?place_id=${placeId}&fields=name,rating,reviews&key=${apiKey}`;

//       try {
//         const response = await fetch(url);
//         if (!response.ok) {
//           console.error(`Error: ${response.status} - ${response.statusText}`);
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log('Full Response:', data); // Log the full response

//         if (data.result && data.result.reviews) {
//           const topReviews = data.result.reviews.slice(0, 5);
//           setReviews(topReviews);
//         } else {
//           console.error("No reviews found for the place");
//         }
//       } catch (error) {
//         console.error("Error fetching reviews: ", error);
//       }
//     };

//     fetchReviews();
//   }, [placeId, apiKey]);

//   // Function to truncate text
//   const truncateText = (text, maxLength) => {
//     return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
//   };

//   // Function to render star ratings with a reduced size
// const renderStars = (rating) => {
//   const fullStars = Math.floor(rating);
//   const halfStar = rating % 1 !== 0;
//   const stars = [];
  
//   const starStyle = { color: '#FFD700', fontSize: '80%' }; // Reduced star size

//   // Create full stars
//   for (let i = 0; i < fullStars; i++) {
//     stars.push(<i className="fas fa-star" style={starStyle} key={`full-${i}`}></i>);
//   }

//   // Create half star
//   if (halfStar) {
//     stars.push(<i className="fas fa-star-half-alt" style={starStyle} key="half"></i>);
//   }

//   return stars;
// };


//   return (
//     <div id="reviews">
//       <div className="container">
//         <div className="section-title text-center">
//           <h2>What our clients say</h2>
//         </div>
//         <div className="row">
//           {reviews.length > 0 ? (
//             reviews.map((review, i) => (
//               <div key={i} className="col-md-4">
//                 <div className="review">
//                   {review.profile_photo_url && (
//                     <div className="review-image">
//                       <img src={review.profile_photo_url} alt={review.author_name} />
//                     </div>
//                   )}
//                   <div className="review-content">
//                     <h4>{review.author_name}</h4>
//                     <div className="rating">
//                       {renderStars(review.rating)}
//                     </div>
//                     <p>"{truncateText(review.text, MAX_CHARACTERS)}"</p>
                    
//                     <div className="review-meta"> - {review.author_name} </div>
//                     <p>{review.relative_time_description}</p>
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div>Loading reviews...</div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
